import * as React from "react"
import { IconProps } from "@app/types"

interface PageContentIconProps extends IconProps {
  strokeIn: string
  strokeOut: string
}

const PageContainIcon = (props: PageContentIconProps) => {
  const restProps = { ...props }
  const { strokeIn, strokeOut } = restProps

  delete restProps.strokeIn
  delete restProps.strokeOut

  return (
    <svg xmlns="http://www.w3.org/2000/svg" {...restProps}>
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h22v22H0z" />
        <path stroke={strokeOut} strokeLinecap="square" strokeLinejoin="round" strokeWidth={1.5} d="M1 1h20v20H1z" />
        <path stroke={strokeIn} strokeLinecap="square" strokeLinejoin="round" strokeWidth={1.5} d="M7 7h8v8H7z" />
      </g>
    </svg>
  )
}
export { PageContainIcon }
