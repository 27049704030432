import * as React from "react"
import { IconProps } from "@app/types"

const RegenerateIcon = ({ stroke = "#000", fill = "#000", width = 20, height = 20, ...rest }: IconProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill="none" {...rest}>
    <path
      stroke={stroke}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.688}
      d="M10.5 10v3h3M18.5 18v-3h-3"
    />
    <path
      stroke={stroke}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.688}
      d="m10.5 13 1.74-1.635a3.375 3.375 0 0 1 5.272.635m.988 3-1.74 1.635A3.375 3.375 0 0 1 11.488 16"
    />
    <path stroke={stroke} strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M7 16H1.5V3.5h15v3" />
    <circle cx={13} cy={7.375} r={1} fill={fill} />
    <path
      stroke={stroke}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M1.5 15.875s.5-9 4.5-9c1.132 0 1.956.615 2.52 1.288"
    />
  </svg>
)
export { RegenerateIcon }
