import * as React from "react"
import { IconProps } from "@app/types"

interface PageTwoVerticalIconProps extends IconProps {
  strokeIn: string
  strokeOut: string
}

const PageTwoVerticalIcon = ({ strokeIn, strokeOut, ...props }: PageTwoVerticalIconProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h22v22H0z" />
        <path stroke={strokeOut} strokeLinecap="square" strokeLinejoin="round" strokeWidth={1.5} d="M1 1h20v20H1z" />
        <path
          stroke={strokeIn}
          strokeLinecap="square"
          strokeLinejoin="round"
          strokeWidth={1.5}
          d="M8 5h6v4.5H8zm0 7.5h6V17H8z"
        />
      </g>
    </svg>
  )
}
export { PageTwoVerticalIcon }
