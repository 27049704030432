import { IconProps } from "@app/types"
import { FC } from "react"

type TEditIconProps = IconProps

const Edit: FC<TEditIconProps> = ({ width = 18, height = 18, stroke = "#fff", ...props }) => (
  <svg width={width} height={height} viewBox="0 0 22 21" fill="none" {...props} xmlns="http://www.w3.org/2000/svg">
    <g>
      <path
        d="M6.82696 19.5555H2.44434V15.1729C2.44434 14.8488 2.57311 14.5379 2.80232 14.3087L14.4134 2.69755C14.8907 2.22025 15.6646 2.22025 16.1419 2.69755L19.3023 5.85796C19.7796 6.33527 19.7796 7.10914 19.3023 7.58644L7.69121 19.1976C7.46199 19.4268 7.15112 19.5555 6.82696 19.5555Z"
        stroke={stroke}
        strokeWidth="1.5"
      />
      <path d="M12.2222 4.88885L17.1111 9.77774" stroke={stroke} strokeWidth="1.5" />
    </g>
  </svg>
)
export { Edit }
