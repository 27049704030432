import { PAGE_TYPES } from "./page-types"

import {
  PageContainIcon,
  PageFillIcon,
  PageFourIcon,
  PageTwoHorizontalIcon,
  PageTwoVerticalIcon,
} from "@app/ui/icons/editor"
import { MobilePageStyleSwitchOption } from "../components/mobile-page-style-switch-option"

const PAGE_STYLE_OPTIONS = [
  {
    children: <PageFillIcon stroke="var(--color-black)" width={22} height={22} />,
    value: PAGE_TYPES.fill,
    tooltip: "bookEditor.pageStyle.fill",
  },
  {
    children: (
      <PageContainIcon strokeIn="var(--color-black)" strokeOut="var(--color-smoke-screen)" width={22} height={22} />
    ),
    value: PAGE_TYPES.contain,
    tooltip: "bookEditor.pageStyle.contain",
  },
  {
    children: (
      <PageTwoHorizontalIcon
        strokeIn="var(--color-black)"
        strokeOut="var(--color-smoke-screen)"
        width={22}
        height={22}
      />
    ),
    value: PAGE_TYPES.two_in_one_vertical,
    tooltip: "bookEditor.pageStyle.twi_horizontal",
  },
  {
    children: (
      <PageTwoVerticalIcon strokeIn="var(--color-black)" strokeOut="var(--color-smoke-screen)" width={22} height={22} />
    ),
    value: PAGE_TYPES.two_in_one_horizontal,
    tooltip: "bookEditor.pageStyle.two_vertical",
  },
  {
    children: (
      <PageFourIcon strokeIn="var(--color-black)" strokeOut="var(--color-smoke-screen)" width={22} height={22} />
    ),
    value: PAGE_TYPES.four_in_one,
    tooltip: "bookEditor.pageStyle.four_photos",
  },
]

const MOBILE_PAGE_STYLE_OPTIONS = [
  {
    children: (
      <MobilePageStyleSwitchOption icon={<PageFillIcon stroke="var(--color-black)" width={22} height={22} />} />
    ),
    value: PAGE_TYPES.fill,
  },
  {
    children: (
      <MobilePageStyleSwitchOption
        icon={
          <PageContainIcon strokeIn="var(--color-black)" strokeOut="var(--color-smoke-screen)" width={22} height={22} />
        }
      />
    ),
    value: PAGE_TYPES.contain,
  },
  {
    children: (
      <MobilePageStyleSwitchOption
        icon={
          <PageTwoHorizontalIcon
            strokeIn="var(--color-black)"
            strokeOut="var(--color-smoke-screen)"
            width={22}
            height={22}
          />
        }
      />
    ),
    value: PAGE_TYPES.two_in_one_vertical,
  },
  {
    children: (
      <MobilePageStyleSwitchOption
        icon={
          <PageTwoVerticalIcon
            strokeIn="var(--color-black)"
            strokeOut="var(--color-smoke-screen)"
            width={22}
            height={22}
          />
        }
      />
    ),
    value: PAGE_TYPES.two_in_one_horizontal,
  },
  {
    children: (
      <MobilePageStyleSwitchOption
        icon={
          <PageFourIcon strokeIn="var(--color-black)" strokeOut="var(--color-smoke-screen)" width={22} height={22} />
        }
      />
    ),
    value: PAGE_TYPES.four_in_one,
  },
]

export { PAGE_STYLE_OPTIONS, MOBILE_PAGE_STYLE_OPTIONS }
