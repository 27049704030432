import * as React from "react"
import { IconProps } from "@app/types"

interface PageFourIconProps extends IconProps {
  strokeIn: string
  strokeOut: string
}

const PageFourIcon = ({ strokeIn, strokeOut, ...props }: PageFourIconProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" {...props}>
      <path
        stroke={strokeOut}
        strokeLinecap="square"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M1 1h20v20H1V1Z"
        clipRule="evenodd"
      />
      <path
        stroke={strokeIn}
        strokeLinecap="square"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M5 5h4.5v4.5H5V5ZM17 5h-4.5v4.5H17V5ZM5 12.5h4.5V17H5v-4.5ZM17 12.5h-4.5V17H17v-4.5Z"
        clipRule="evenodd"
      />
    </svg>
  )
}
export { PageFourIcon }
