import * as React from "react"
import { IconProps } from "@app/types"

interface PageTwoHorizontalIconProps extends IconProps {
  strokeIn: string
  strokeOut: string
}

const PageTwoHorizontalIcon = ({ strokeIn, strokeOut, ...props }: PageTwoHorizontalIconProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h22v22H0z" />
        <path stroke={strokeOut} strokeLinecap="square" strokeLinejoin="round" strokeWidth={1.5} d="M1 1h20v20H1z" />
        <path
          stroke={strokeIn}
          strokeLinecap="square"
          strokeLinejoin="round"
          strokeWidth={1.5}
          d="M17 8v6h-4.5V8zM9.5 8v6H5V8z"
        />
      </g>
    </svg>
  )
}
export { PageTwoHorizontalIcon }
