import * as React from "react"
import { IconProps } from "@app/types"

const KebabIcon = ({ width = 4, height = 16, fill = "#000", stroke = "#000", ...props }: IconProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill="none" {...props}>
    <circle cx={2} cy={2} r={1} fill={fill} stroke={stroke} />
    <circle cx={2} cy={8} r={1} fill={fill} stroke={stroke} />
    <circle cx={2} cy={14} r={1} fill={fill} stroke={stroke} />
  </svg>
)

export { KebabIcon }
