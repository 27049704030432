import * as React from "react"
import { IconProps } from "@app/types"
const ClearIcon = ({ stroke = "#000", fill = "#000", width = 20, height = 20, ...rest }: IconProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="none" {...rest}>
    <path stroke="#000" strokeLinecap="round" strokeWidth={1.5} d="M11 12h7" />
    <path
      stroke={stroke}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="m17.5 12-.5 6h-5l-.5-6M12.5 12v-1h4v1M7 16H1.5V3.5h15v3"
    />
    <circle cx={13} cy={7.375} r={1} fill={fill} />
    <path
      stroke={stroke}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M1.5 15.875s.5-9 4.5-9c1.524 0 2.49 1.115 3.013 1.986"
    />
  </svg>
)

export { ClearIcon }
