import { IconProps } from "@app/types"

const Delete = ({ width, height, fill = "#000", stroke = "#000", className, ...props }: IconProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 20 20" fill="none">
    <path d="M1 5H3H19" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M17 5V17C17 18.1046 16.1046 19 15 19H5C3.89543 19 3 18.1046 3 17V5"
      stroke={stroke}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M8 8.75V14.25" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M12 8.75V14.25" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M5 3C5 1.89543 5.89543 1 7 1H13C14.1046 1 15 1.89543 15 3V5H5V3Z"
      stroke={stroke}
      strokeWidth="1.5"
      strokeLinejoin="round"
    />
  </svg>
)
export { Delete }
