import React from "react"

import styles from "./mobile-page-style-switch-option.module.scss"
import { useI18n } from "next-localization"

interface MobilePageStyleSwitchOptionProps {
  icon: any
}

const MobilePageStyleSwitchOption = ({ icon }: MobilePageStyleSwitchOptionProps): JSX.Element => {
  return <React.Fragment>{icon}</React.Fragment>
}

export { MobilePageStyleSwitchOption }
