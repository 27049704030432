import * as React from "react"
import { IconProps } from "@app/types"

const PageFillIcon = (props: IconProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h22v22H0z" />
      <path stroke={props.stroke} strokeLinecap="square" strokeLinejoin="round" strokeWidth={1.5} d="M1 1h20v20H1z" />
    </g>
  </svg>
)
export { PageFillIcon }
