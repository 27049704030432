import { numberFormatPrice } from "@app/features/product/utils/number-format-price"

const formatPrices = (products) => {
  const prices = {}

  products.forEach((product) => {
    const priceFormat = numberFormatPrice(product.price.price)
    prices[product.handle] = { price: priceFormat, currency: product.price.currency.handle }
  })

  return prices
}

export { formatPrices }
